import React from "react"
// import { graphql } from "gatsby"

import SEO from "../components/Seo"

const TermsOfService = () => {
  // function flattenData(newData) {
  //   let flatArray = []

  //   newData.forEach(({ rows }) => {
  //     let flatRow = []
  //     rows.forEach(({ base, translation }) => {
  //       flatRow.push(
  //         `/b/ ${base} ${translation.value || translation.masculine} /t/`
  //       )
  //     })
  //     flatArray.push(flatRow)
  //   })
  //   return flatArray.flat().join(" , ")
  // }

  // function transformerData() {
  //   return data.allSitePage.nodes.map(({ path, context }) => ({
  //     path,
  //     title: context.table.title,
  //     translation: flattenData(context.data),
  //   }))
  // }

  // transformerData()

  // console.log(transformerData())

  return (
    <>
      <SEO title="TermsOfService" />
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
    </>
  )
}

// export const query = graphql`
//   query vocab {
//     allSitePage(
//       filter: { path: { regex: "/vocabulary/", ne: "/vocabulary/" } }
//     ) {
//       nodes {
//         path
//         context {
//           table {
//             title
//           }
//           data {
//             category
//             rows {
//               base
//               translation {
//                 value
//                 masculine
//                 feminine
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default TermsOfService
